<div class="wrapper">
  <div class="inner_wrapper">
    <div class="right_section">

      <div class="search_box">
        <div id="search-autocomplete" class="form-outline search_box_content">
          <input type="search" placeholder="Search" #box [(ngModel)]="searchText" (keyup)="searchData(box.value)"
            (search)="search()" id="form1" class="form-control" />
          <button type="button" class="btn btn_search" *ngIf="hide">
            <i class="fa fa-search"></i>
          </button>
        </div>

      </div>
      <div class="search_control scroll_section">
        <ul class="search_list list-unstyled">
          <li><span class="toggle-icon">
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </span><a [routerLink]="['/list']">Home</a></li>
          <li><a (click)="togglediv(); showFirst=!showFirst">
              <span class="toggle-icon">
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </span> EHI Specifications </a>
            <ul id="dropdown-menu" class="list-unstyled menu">
              <li *ngFor="let row of searchResourceList, let i=index" (click)="showResourceDetails(row)"
                [ngClass]="getActiveClass(row?.module)" style="display: flex;
                align-items: baseline;">
                <i class="fa fa-square" aria-hidden="true"></i><a>{{row?.module}}</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="left_section">

      <div class="heading_content pb-0">
        <h1 class="wrapper_content_heading">EHI Specifications</h1>
        <div>
          <h3 class="heading">{{selectedItem}}</h3>
          <!-- <span class="summary_count">R4</span> -->
        </div>
      </div>

      <div class="wrapper_content pt-0 scroll_section2">
        <div class="left_section">
          <div class="pl-10">
            <!-- <div class="general_info_section">
                <p class="info_header">General Information</p>
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-5 col-5">
                    <span class="text-warning"> Http Method: </span>
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-7 col-7">
                    <span>GET</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-5 col-5">
                    <span class="text-warning"> Url Template: </span>
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-7 col-7">
                    <span>
                      {{url}}
                      <br></span>
                  </div>
                </div>
              </div> -->
            <div class="documentation_section">
              <h3 class="document_header">Description</h3>
              <p class="des_p">
                {{description | titlecase}}
              </p>

              <!-- <div class="accordion" id="request">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="requestOpen-headingOne">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#requestOpen-collapseOne" aria-expanded="true"
                        aria-controls="requestOpen-collapseOne">
                        Request
                      </button>
                    </h2>
                    <div id="requestOpen-collapseOne" class="accordion-collapse collapse show"
                      aria-labelledby="requestOpen-headingOne">
                      <div class="accordion-body">
                        <p>{{url}} <br>
                        </p>
                      </div>
                    </div>
                    <div class="accordion-body-empty">
                      <p>. . .</p>
                    </div>
                  </div>
                </div> -->


               



                
              <div class="table-responsive scroll_section" *ngIf="selectedItem != 'Patient Cases'">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th width="250">Name</th>
                      <th width="350">Description</th>
                      <!-- <th width="120">Is Required</th> -->
                      <th width="120">Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let dataItem of resourceContent">
                      <td>
                        {{dataItem.field}}
                      </td>
                      <td>
                        {{dataItem.description}}
                      </td>
                      <!-- <td>
                            false
                          </td> -->
                      <td>
                        {{dataItem.type}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div *ngIf="selectedItem == 'Patient Cases'">
                <!-- Patient Cases - PriorAuthorizationMst -->
                <h3 class="document_header">Patient Cases - PriorAuthorizationMst</h3>
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th width="250">Name</th>
                        <th width="350">Description</th>
                        <!-- <th width="120">Is Required</th> -->
                        <th width="120">Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let dataItem of PriorAuthorizationMst">
                        <td>
                          {{dataItem.field}}
                        </td>
                        <td>
                          {{dataItem.description}}
                        </td>
                        <!-- <td>
                            false
                          </td> -->
                        <td>
                          {{dataItem.type}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Patient Cases - Master -->
                <h3 class="document_header">Patient Cases - Master</h3>

                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th width="250">Name</th>
                        <th width="350">Description</th>
                        <!-- <th width="120">Is Required</th> -->
                        <th width="120">Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let dataItem of Master">
                        <td>
                          {{dataItem.field}}
                        </td>
                        <td>
                          {{dataItem.description}}
                        </td>
                        <!-- <td>
                            false
                          </td> -->
                        <td>
                          {{dataItem.type}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Patient Cases - InsurancePlan -->
                <h3 class="document_header">Patient Cases - InsurancePlan</h3>

                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th width="250">Name</th>
                        <th width="350">Description</th>
                        <!-- <th width="120">Is Required</th> -->
                        <th width="120">Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let dataItem of InsurancePlan">
                        <td>
                          {{dataItem.field}}
                        </td>
                        <td>
                          {{dataItem.description}}
                        </td>
                        <!-- <td>
                            false
                          </td> -->
                        <td>
                          {{dataItem.type}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Patient Cases - Diagnosis -->
                <h3 class="document_header">Patient Cases - Diagnosis</h3>

                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th width="250">Name</th>
                        <th width="350">Description</th>
                        <!-- <th width="120">Is Required</th> -->
                        <th width="120">Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let dataItem of Diagnosis">
                        <td>
                          {{dataItem.field}}
                        </td>
                        <td>
                          {{dataItem.description}}
                        </td>
                        <!-- <td>
                            false
                          </td> -->
                        <td>
                          {{dataItem.type}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <!-- <div class="table-responsive scroll_section" *ngIf="selectedItem != 'DMS Document Flat'">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th width="250">FileName</th>
                      <th width="350">PatientID</th>
                      <th width="120">Last Name</th>
                      <th width="120">First Name</th>
                      <th width="120">DOB</th>
                      <th width="120">CreatedDateTime</th>
                      <th width="120">DocumentTitle</th>
                      <th width="120">DocumentCategory</th>
        
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let dataItem of DMSData">
                      <td>
                        {{dataItem.FileName}}
                      </td>
                      <td>
                        {{dataItem.PatientID}}
                      </td>
                      <td>
                        {{dataItem.LastName}}
                      </td>
                      <td>
                        {{dataItem.FirstName}}
                      </td>
                      <td>
                        {{dataItem.DOB}}
                      </td>
                      <td>
                        {{dataItem.CreatedDateTime}}
                      </td>
                      <td>
                        {{dataItem.DocumentTitle}}
                      </td>
                      <td>
                        {{dataItem.DocumentCategory}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> -->



              <!-- <div class="accordion" id="response">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="responseOpen-headingOne">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#responseOpen-collapseOne" aria-expanded="true"
                        aria-controls="responseOpen-collapseOne">
                        Response
                      </button>
                    </h2>
                    <div id="responseOpen-collapseOne" class="accordion-collapse collapse show"
                      aria-labelledby="responseOpen-headingOne">
                      <div class="accordion-body">
                        <div class="table-responsive">
                          <table class="table table-bordered">
                            <thead>
                              <tr>
                                <th width="120">Name</th>
                                <th width="120">Type</th>
                                <th width="120">Cardinality</th>
                                <th width="120">Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  name
                                </td>
                                <td>
                                  gcbnhgvngfh
                                </td>
                                <td>
                                  fthtfhfhh
                                </td>
                                <td>
                                  Portion of either the family or given name of the patient
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-body-empty">
                      <p>. . .</p>
                    </div>
                  </div>
                </div> -->
              <!-- <div>
                  <h5 class="font-bold">Response Code</h5>
                  <ul>
                    <li class="font-bold">567</li>
                    <pre style="color: black;">6789</pre>

                  </ul>
                </div> -->
            </div>
          </div>
        </div>


      </div>


  

      <div class="wrapper_content  imgwrapper">
        <i>Loading...</i>
        <!-- <kendo-loader [type]="loaderType" [themeColor]="loadrethemeColor" [size]="loadersize">
          </kendo-loader> -->
      </div>
      <div class="wrapper_content ">
        <div class="imgwrapper">
          <img src="../../../../assets/img/progressimg.png">
        </div>
      </div>
    </div>

  </div>

</div>