<div class="wrapper">
    <div class="container">
        <div class="inner_wrapper">
            <div class="logo">
                <h4 class="logo-title">Electronic Health Information export</h4>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <p>TRIARQ Health QSuite EHI Export consists of Bulk CDA Exports in USCDI v1 and data in csv and pdf
                        format. Image files are also available for export. The EHI also supports creating
                        single-patient ePHI export and bulk data export for patient populations. 
                        This feature is available in QSuite Manistee v11.10 onwards.
                    </p>

                </div>
            </div>

            <div class="summary_resource">
                <h3 class="heading">Summary</h3>
                <h5 class="summarylist-title-a"><strong>CCDA</strong></h5>

                <!-- <p class="font-13 anchorlink">The United States Core Data for Interoperability (USCDI) is a standardized set of health data classes and constituent data elements for nationwide, interoperable health information exchange. Review the <a href="https://www.healthit.gov/sites/default/files/page2/2020-03/USCDI.pdf" class="tri-primary">USCDI Fact Sheet</a> to learn more.</p> -->
                <p class="font-13 anchorlink">Export of HL7 CCDA xml files which comply to <a
                        href="https://www.healthit.gov/isa/united-states-core-data-interoperability-uscdi"
                        class="tri-primary">United States Core Data for Interoperability (USCDI),</a> Version 1
                    requirements. The specifications for the CCDA can be obtained from the HL7 website:<a
                        href="https://www.hl7.org/implement/standards/product_brief.cfm?product_id=447"
                        class="tri-primary"><br>HL7 Standards Product Brief - HL7 CDA® R2 IG: C-CDA Templates for
                        Clinical
                        Notes R2.1 Companion Guide, Release 4.1 - US
                        Realm | HL7 International</a></p>
                <div class="pt-3">
                    <h5 class="summarylist-title-a"><strong>CSV</strong></h5>
                    <!-- <p class="font-13 anchorlink">The United States Core Data for Interoperability (USCDI) is a standardized set of health data classes and constituent data elements for nationwide, interoperable health information exchange. Review the  <a href="https://www.healthit.gov/sites/default/files/page2/2020-03/USCDI.pdf" class="tri-primary">USCDI Fact Sheet</a> to learn more.</p> -->
                </div>

                <div class="summary_resource_list py-3">
                    <div class="row">
                        <div class="col-lg-3 mb-3" *ngFor="let resource of resourceList">
                            <div class="d-flex list-link" (click)="resourceDetails(resource)">
                                <img src="../../../../assets/img/csv.png" class="summarylist-icon">
                                <h5 class="summarylist-title">{{resource.module}}</h5>
                            </div>
                        </div>
                        <!-- <div class="col-lg-3 mb-3" >
                            <div class="d-flex list-link"  [routerLink]="['/syntax']">
                                <img src="../../../../assets/img/csv.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Insurances</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list-link"  [routerLink]="['/syntax']">
                                <img src="../../../../assets/img/csv.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Primary Care Physician</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list-link"  [routerLink]="['/syntax']">
                                <img src="../../../../assets/img/csv.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Cases</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list-link"  [routerLink]="['/syntax']">
                                <img src="../../../../assets/img/csv.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Care Team</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list-link"  [routerLink]="['/syntax']">
                                <img src="../../../../assets/img/csv.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Guarantors</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list-link"  [routerLink]="['/syntax']">
                                <img src="../../../../assets/img/csv.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Allergy</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list-link"  [routerLink]="['/syntax']">
                                <img src="../../../../assets/img/csv.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Immunization</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list-link"  [routerLink]="['/syntax']">
                                <img src="../../../../assets/img/csv.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Vitals</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list-link"  [routerLink]="['/syntax']">
                                <img src="../../../../assets/img/csv.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Medications</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list-link">
                                <img src="../../../../assets/img/csv.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Prior Authorization</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list-link"  [routerLink]="['/syntax']">
                                <img src="../../../../assets/img/csv.png" class="summarylist-icon">
                                <h5 class="summarylist-title">History</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list-link"  [routerLink]="['/syntax']">
                                <img src="../../../../assets/img/csv.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Patient Alert</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list-link"  [routerLink]="['/syntax']">
                                <img src="../../../../assets/img/csv.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Pharmacy</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list-link"  [routerLink]="['/syntax']">
                                <img src="../../../../assets/img/csv.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Appointments</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list-link"  [routerLink]="['/syntax']">
                                <img src="../../../../assets/img/csv.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Referrals</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list-link"  [routerLink]="['/syntax']">
                                <img src="../../../../assets/img/csv.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Problems</h5>
                            </div>
                        </div> -->
                    </div>
                </div>

                <!-- <h4 class="summarylist-title-a "><strong>Other</strong></h4> -->

                <div class="pt-2">
                    <h5 class="summarylist-title-a"><strong>PDF</strong></h5>
                </div>

                <div class="summary_resource_list py-3">
                    <div class="row">
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list">
                                <img src="../../../../assets/img/pdf.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Patient Messages</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list">
                                <img src="../../../../assets/img/pdf.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Lab Orders Documents</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list">
                                <img src="../../../../assets/img/pdf.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Exam Notes</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list">
                                <img src="../../../../assets/img/pdf.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Patient Letter</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list">
                                <img src="../../../../assets/img/pdf.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Patient Consent</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list">
                                <img src="../../../../assets/img/pdf.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Patient Order Template</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list">
                                <img src="../../../../assets/img/pdf.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Nurse Notes</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list">
                                <img src="../../../../assets/img/pdf.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Patient Education</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pt-2">
                    <h5 class="summarylist-title-a"><strong>Word</strong></h5>
                </div>

                <div class="summary_resource_list py-3">
                    <div class="row">
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list">
                                <img src="../../../../assets/img/doc.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Exam Notes</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list">
                                <img src="../../../../assets/img/doc.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Nurse Notes</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list">
                                <img src="../../../../assets/img/doc.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Billing Statements</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list">
                                <img src="../../../../assets/img/doc.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Collections</h5>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="pt-2">
                    <h5 class="summarylist-title-a"><strong>Image</strong></h5>
                </div>

                <div class="summary_resource_list py-3">
                    <div class="row">
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list">
                                <img src="../../../../assets/img/picture.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Driver License</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list">
                                <img src="../../../../assets/img/picture.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Insurance Card</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list">
                                <img src="../../../../assets/img/picture.png" class="summarylist-icon">
                                <h5 class="summarylist-title">DMS Documents</h5>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <div class="d-flex list">
                                <img src="../../../../assets/img/picture.png" class="summarylist-icon">
                                <h5 class="summarylist-title">Other</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>